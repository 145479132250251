<template>
    <div class="listaNewsletter">
        <div class="innerCont">
            <div :class="['estendi', 'noselect', { open: passatiOpen }]" @click="togglePassati">
                <div class="titlebody">{{ group.year }}</div>
                <NIconWrapper :size="22" :border-radius="11">
                    <NIcon size="1.176rem" color="var(--color-text-default)" :component="KeyboardArrowRightRound" :aria-label="$t('apri')"></NIcon>
                </NIconWrapper>
            </div>
        </div>
        <NCollapseTransition :show="passatiOpen">
            <div class="grid">
                <div class="gridItem" v-for="card in group.cards.sort((a: any, b: any) => b.timestamp - a.timestamp)" :key="card.id">
                    <OggettoNewsletter :card="card"></OggettoNewsletter>
                </div>
            </div>
        </NCollapseTransition>
    </div>
</template>

<script lang="ts" setup>
import { NIconWrapper, NIcon, NCollapseTransition } from 'naive-ui';
import { KeyboardArrowRightRound } from '@vicons/material'

const props = defineProps<{ group: any }>()

const passatiOpen = ref(false)

const togglePassati = () => {
    passatiOpen.value = !passatiOpen.value
}
</script>

<style lang="scss" scoped>
.listaNewsletter {
    .estendi {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;

        &:deep(.n-icon-wrapper) {
            svg {
                transition: transform 300ms 0ms cubic-bezier(0.4, 0, 0.2, 1);
            }
        }

        &.open {
            &:deep(.n-icon-wrapper) {
                svg {
                    transform: rotate(90deg);
                }
            }
        }
    }

    .grid {
        padding-top: var(--margin-default);
        display: grid;
        gap: var(--margin-gap);
        grid-template-columns: repeat(5, minmax(0, 1fr));
        grid-auto-rows: 400px;

        .gridItem {
            position: relative;
        }
    }
}

@media screen and (max-width: 1060px) {
    .listaNewsletter {
        .grid {
            grid-template-columns: repeat(4, minmax(0, 1fr));
        }
    }
}

@media screen and (max-width: 840px) {
    .listaNewsletter {
        .grid {
            grid-template-columns: repeat(3, minmax(0, 1fr));
        }
    }
}

@media screen and (max-width: 600px) {
    .listaNewsletter {
        .grid {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }
    }
}

@media screen and (max-width: 480px) {
    .listaNewsletter {
        .grid {
            grid-template-columns: 1fr;
            grid-auto-rows: 300px;
        }
    }
}
</style>